import gql from 'graphql-tag';

const CREATE_CONTRIBUTE = gql`mutation ($createContributeData: ContributionInput!) {
  createContribute(data: $createContributeData) {
    success
  }
}`;

export {
  CREATE_CONTRIBUTE,
}
