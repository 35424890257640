<template>
  <v-container>
    <v-card flat min-height="">
      <v-card-text class="text-center" style="padding-top:70px; padding-bottom: 70px;">
        <span class="text-h4">
          Want to join the team?
        </span> 
      </v-card-text>
    </v-card>

    <v-row class="mt-5">
      <v-col cols="12" sm="6" md="6">
        <v-card color="transparent" flat>
          <v-card-title>Contribution Methods</v-card-title>
          <v-card-text>
            <p> 
              haq.ai is an open and collaborative project that you can contribute to in several ways including:
            </p>
            <ul>
              <li> Problem creation and challenge creation </li>
              <li> Problem Testing and UI Improvement </li>
              <li>
               Moderating our community and creating a team of students that you can mentor
              </li>
              <li>
                Any other idea you come up with, we’ll take into consideration
              </li>
            </ul>
          </v-card-text>
        </v-card>

        <v-card color="transparent" class="mt-4" flat>
          <v-card-title>Collaboration Methods</v-card-title>
          <v-card-text>
            <p> 
              As stated above, we would be highly interested in collaboration. If you are interested in the following, please contact us:
            </p>
            <ul>
              <li> Hosting your hackathon on haq and having people’s scores automatically given  </li>
              <li> Creating your own challenge for your company or orginization </li>
              <li>
                Co-Hosting a Hackathon with The Eigensolvers
              </li>
              <li>
                Any other idea you have, whether hackathon related or not
              </li>
            </ul>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-snackbar
          v-model="snackbar"
          top
          right
          :color="actionClass"
        >
          {{ message }}
        </v-snackbar>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card class="rounded-lg">
            <v-card-text class="pt-10 mx-10">
              <v-row>
                <v-col cols=3>
                  <label for=""> Name</label>
                </v-col>
                <v-col>
                  <v-text-field
                  v-model="name"
                  placeholder=""
                  outlined
                  class="rounded-lg ml-3 mr-15"
                  :rules="requiredValidator('Name')"
                  >
                  </v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols=3>
                  <label for=""> Email Address</label>
                </v-col>
                <v-col>
                  <v-text-field
                  v-model="email"
                  placeholder=""
                  outlined
                  class="rounded-lg ml-3 mr-15"
                  :rules="emailRules"
                  >
                  </v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols=3>
                  <label for=""> Topic</label>
                </v-col>
                <v-col>
                  <v-text-field
                  v-model="topic"
                  placeholder=""
                  outlined
                  class="rounded-lg ml-3 mr-15"
                  :rules="requiredValidator('Topic')"
                  >
                  </v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols=3>
                  <label for=""> Description </label>
                </v-col>
                <v-col>
                  <v-textarea
                  v-model="description"
                  placeholder=""
                  outlined
                  class="rounded-lg ml-3 mr-15"
                  :rules="requiredValidator('Description')"
                  >
                  </v-textarea>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols=3>
                  <label for=""> Github Link <br/> (Optional)</label>
                </v-col>
                <v-col>
                  <v-text-field
                  v-model="link"
                  placeholder=""
                  outlined
                  class="rounded-lg ml-3 mr-15"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-actions class="pb-5 mx-15">
              <v-spacer></v-spacer>
              <v-btn :disabled="!valid" :color="primaryBlue" class="px-5" dark :class="{'black--text': theme }" :loading="loading" @click="submit"> Submit </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import colorMxn from '@/mixins/color_mixin';
import formMxn from '@/mixins/form_mixin';
import { CREATE_CONTRIBUTE } from '@/queries/contribution';

export default {
  name: 'Contribute',
  mixins: [colorMxn, formMxn],
  data() {
    return {
      latex: '',
      valid: true,
      name: '',
      email: '',
      topic: '',
      description: '',
      link: '',
      loading: false,
      snackbar: false,
      actionClass: 'success',
      message: 'error'
    }
  },
  methods: {
    submit() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.loading = true;
      this.$apollo.mutate({
        mutation: CREATE_CONTRIBUTE,
        variables: {
          "createContributeData": {
            "name":this.name,
            "emailAddress": this.email,
            "description":this.description,
            "githubLink": this.link,
            "topic": this.topic
          },
        },
        update: (cache, {data, errors}) => {
          console.log(data);
          if (!errors) {
            this.loading = false;
            this.actionClass = "success";
            this.message = "Contribution Submitted Succesfully"
            this.snackbar = true;
            this.$refs.form.reset();
          }
        },
      }) 
    
    }
  }
}
</script>